<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">
          Admins
          <v-btn
            fab
            color="primary accent-2"
            x-small
            @click="addItem"
            v-auth="'USER.ADD'"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-text>
            <Paginator
              v-bind.sync="adminList.pagingOptions"
              @page="pageSelected"
            ></Paginator>

            <v-data-table
              :headers="adminList.headers"
              :items="adminList.data"
              :items-per-page="25"
              :disable-sort="true"
              :loading="adminList.loading"
              no-data-text=""
              loading-text="Carregando dados..."
              class="app-table hover-table"
              @click:row="viewItem"
              hide-default-footer
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  :color="item.status | enum(data.userStatus, 'id', 'class')"
                  dark
                  >{{ item.status | enum(data.userStatus) }}</v-chip
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userStatus from "@/common/data/user-status.data";
import userApi from "@/common/api/user.api";
import Paginator from "@/components/layout/Paginator";

export default {
  name: "AdminList",

  components: {
    Paginator,
  },

  data: () => ({
    data: {
      userStatus: userStatus,
    },
    adminList: {
      filter: {},
      headers: [
        { text: "Nome", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "Status", value: "status", align: "center" },
      ],
      data: [],
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
    },
  }),

  created() {
    this.list();
  },

  methods: {
    pageSelected(page) {
      this.adminList.pagingOptions.currentPage = page;
      this.list();
    },

    addItem() {
      this.$router.push(`admins/novo`);
    },

    list(resetPage) {
      if (resetPage) {
        this.adminList.pagingOptions.currentPage = 1;
      }

      this.adminList.filter.page = this.adminList.pagingOptions.currentPage;
      this.adminList.filter.onlyAdmin = true;

      this.adminList.loading = true;
      userApi.list(this.adminList.filter).then((result) => {
        this.adminList.loading = false;
        this.adminList.data = result.data.data;
        this.adminList.pagingOptions = result.data.pagingOptions;
      });
    },

    viewItem(item) {
      this.$router.push(`admins/${item.id}`);
    },
  },
};
</script>